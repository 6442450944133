.banner {
  position: sticky;
  width: 100%;
  background-color: #EF8200;
  padding: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90px;
  color: white;
}

.found-school {
  position: absolute;
  left: 20px;
  font-weight: bold;
}

.banner .logo-icon {
  max-width: 100%;
  max-height: 60px;
}

.banner-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.select-container {
  position: absolute;
  right: 20px;
  display: flex;
  align-items: center;

}

.custom-select__control {
  position: absolute;
  right: 20px;
  font-weight: bold;
  color: #EF8200 !important;
  background-color: white;
  background-image: url('../images/photobook-icons-calendar.svg');
  background-repeat: no-repeat;
  background-size: 25px 25px;
  background-position: center left;
  background-position-x: 15px;
  padding: 10px;
  font-weight: bold;
  height: 50px;
  width: 200px;
  border-radius: 30px;
  padding-left: 30px;
  border: 2px solid #EF8200;
}

.react-select-container {
  border-color: #EF8200;
}

.react-select-container .custom-select__indicator svg {
  fill: #EF8200;
}

.react-select-container .custom-select__indicator-separator {
  background-color: #EF8200;
}



/* mobile-specific styles */
@media (max-width: 768px) {
  .banner {
    height: 60px;
    justify-content: flex-start;
    padding-left: 10px;
  }

  .found-school {
    display: none;
  }

  .banner .logo-icon {
    max-height: 50px;
  }

  .banner {
    height: 60px;
  }
}