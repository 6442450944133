html {
  background-color: #FBF2E8;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  background-color: #FBF2E8;
  overflow: hidden;
  color: #EF8200;
  height: calc(100vh - 100px);
  background-image: url('../images/photobook-icons-kids.svg');
  background-repeat: no-repeat;
  background-size: 30%;
  background-position: left bottom;
}

.login-box {
  background-color: white;
  padding: 15px;
  border-radius: 15px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 60%;
  margin-top: 10vh;
  border: 2px solid #EF8200;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  overflow: hidden;
  min-height: 30vh;
}

/* LoginPage.css */
.login-content {
  flex: 1;
  padding-right: 20px;
}

.login-image-container {
  flex: 0 0 30%;
  max-width: 30%;
  position: relative;
  overflow: hidden;
  height: 100%;
  margin: -25px;
}

.login-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Input and buttons */
input[type="password"] {
  border: 2px solid #EF8200;
  border-radius: 15px;
  padding: 10px;
  width: 80%;
  height: 30px;
  font-size: 16px;
  background-image: url('../images/photobook-icons-key.svg');
  background-size: 25px 25px;
  background-repeat: no-repeat;
  background-position: center left;
  background-position-x: 10px;
  padding-left: 45px;
}

input[type="password"]:focus {
  border: 3px solid #EF8200;
  outline: none;
}

h2 {
  font-size: 20px;
}

.login-button {
  background-color: #EF8200;
  color: white;
  padding: 15px 70px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: bold;
}

.login-button:hover {
  background-color: #D76F00;
}

.error-message {
  color: #D76F00;
  margin-top: 10px;
  font-size: 14px;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .login-container {
    background-size: 60%;
    background-position: center bottom;
    justify-content: center;
  }

  .login-box {
    background-color: transparent;
    box-shadow: none;
    border: none;
    width: 90%;
    padding: 0;
    display: flex;
    flex-direction: column; 
    align-items: center; 
  }

  .login-image-container {
    display: none; 
  }

  input[type="password"] {
    width: calc(100% - 45px);
    background-position: center left;
    background-position-x: 10px;
    background-color: #FBF2E8;
  }

  h2 {
    font-size: 18px;
  }

  .login-button {
    padding: 15px 50px;
  }
}
