.spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 6px solid #EF8200; 
    width: 30px;
    height: 30px;
    position: absolute; 
    animation: spin 1s linear infinite;
    margin-top: 5px;
    left: 10%;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  /* Media query for mobile devices */
@media (max-width: 768px) {
  .spinner {
      display: none;
  }
}